import Vue from 'vue'
import App from './layout/App'
import WorldMap from './WorldMap'
import ThemeCard from './ThemeCard'
import FacultyGallery from './FacultyGallery'
import PartnerGallery from './PartnerGallery'
import FAQQuestions from './FAQQuestions'
import FAQSearch from './FAQSearch'
import FAQFeedback from './FAQFeedback'
import VueMask from 'v-mask'
import Roadmap from './Roadmap'
;[
  App,
  {
    name: 'roadmap',
    ...Roadmap
  },
  {
    name: 'world-map',
    ...WorldMap
  },
  {
    name: 'theme-card',
    ...ThemeCard
  },
  {
    name: 'faculty-gallery',
    ...FacultyGallery
  },
  {
    name: 'faq-questions',
    ...FAQQuestions
  },
  {
    name: 'faq-search',
    ...FAQSearch
  },
  {
    name: 'faq-feedback',
    ...FAQFeedback
  },
  {
    name: 'partner-gallery',
    ...PartnerGallery
  }
  // if not component name
  // { name: 'component-name', ...Component }
].forEach(Component => {
  if (!Component.name) {
    throw new Error(`Not component name: ${Component}`)
  }

  Vue.use(VueMask)
  Vue.component(Component.name, Component)
})
