export const state = {
  userQuestion: ''
}

export const getters = {
  userQuestion: state => state.userQuestion
}

export const mutations = {
  updateUserQuestion(state, question) {
    state.userQuestion = question
  }
}
