<template>
  <div>
    <div @click="showModal = !showModal" class="faq__header-button">
      Задать вопрос
    </div>
    <transition name="opacity">
      <div
        class="faq__feedback-mask"
        v-if="showModal"
        @click="showModal = false"
      ></div>
    </transition>
    <transition name="fade">
      <div v-if="showModal" class="faq__feedback-window">
        <div @click="showModal = false" class="faq__feedback-window__close">
          <i class="fas fa-times"></i>
        </div>
        <form class="row" @submit.prevent="sendData">
          <div class="col-12 py-2">
            <h1 class="faq__feedback-title pb-3">Задать вопрос</h1>
          </div>
          <div class="col-md-6 py-2 col-12">
            <div class="faq__feedback-label">Ф.И.О.</div>
          </div>
          <div class="col-md-6 py-2 col-12">
            <input
              class="faq__feedback-input"
              required
              @input="validate"
              type="text"
              name="fio"
              placeholder="Ф.И.О."
            />
          </div>
          <div class="col-md-6 py-2 col-12">
            <div class="faq__feedback-label">Телефон</div>
          </div>
          <div class="col-md-6 py-2 col-12">
            <input
              class="faq__feedback-input"
              required
              @input="validate"
              v-mask="'+7 (###) ###-##-##'"
              v-model="phone"
              name="phone"
              type="phone"
              placeholder="Телефон"
            />
          </div>
          <div class="col-md-6 py-2 col-12">
            <div class="faq__feedback-label">E-mail</div>
          </div>
          <div class="col-md-6 py-2 col-12">
            <input
              class="faq__feedback-input"
              required
              @input="validate"
              name="email"
              type="email"
              placeholder="E-mail"
            />
          </div>
          <div class="col-md-6 py-2 col-12">
            <div class="faq__feedback-label">Тематика вопроса</div>
          </div>
          <div class="col-md-6 py-2 col-12">
            <select
              class="faq__feedback-input"
              type="select"
              name="subject"
              @input="validate"
              required
            >
              <option value="" selected="">---------</option>
              <option
                v-for="type in questionTypes"
                :key="type.id"
                :value="type.id"
              >
                {{ type.subject }}
              </option>
            </select>
          </div>
          <div class="col-md-6 py-2 col-12">
            <div class="faq__feedback-label">Вопрос</div>
          </div>
          <div class="col-md-6 py-2 col-12">
            <textarea
              class="faq__feedback-input"
              @input="validate"
              name="question"
              type="text"
              required
              placeholder="Ваш вопрос"
            />
          </div>
          <div class="offset-md-6 col-md-6 py-2 col-12">
            <button type="submit" class="faq__feedback-button pt-3">
              Отправить вопрос
            </button>
          </div>
        </form>
      </div>
    </transition>
  </div>
</template>

<script>
  import axios from 'axios'
  import Swal from 'sweetalert2'

  export default {
    data() {
      return {
        showModal: false,
        questionTypes: [],
        phone: ''
      }
    },
    methods: {
      validate(e) {
        const el = e.currentTarget
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        el.classList.remove('error', 'success')
        if ('text textarea select'.includes(el.getAttribute('type'))) {
          el.classList.add(el.checkValidity() ? 'success' : 'error')
        } else if (el.getAttribute('type') === 'phone') {
          el.classList.add(el.value.length > 17 ? 'success' : 'error')
        } else if (el.getAttribute('type') === 'email') {
          el.classList.add(re.test(el.value.toLowerCase()) ? 'success' : 'error')
        }
      },
      getQuestionsTypes() {
        axios
          .get('/feedback/types/get/')
          .then((response) => {
            this.questionTypes = response.data
          })
          .catch((error) => {
            console.log(error)
            Swal.fire({
              title: 'Ошибка',
              text: 'Не удалось получить ответ сервера',
              icon: 'error',
              toast: true,
              timer: 1000,
              showConfirmButton: false
            })
          })
      },
      sendData(e) {
        const form = e.currentTarget
        const data = new FormData(form)
        axios.post('feedback/create/', data).then((respone) => {
          form.reset()
          form.querySelectorAll('input,select,textarea').forEach(el => {
            el.classList.remove('error', 'success')
          })
          this.showModal = false
          this.phone = ''
          Swal.fire({
            title: 'Успешно отправлено',
            icon: 'success',
            timer: 1000,
            toast: true,
            showConfirmButton: false
          })
        }).catch(error => {
          console.log(error)
          Swal.fire({
            title: 'Возникла ошибка',
            text: 'Проверьте правильность введенных данных и попробуйте еше раз',
            icon: 'error',
            timer: 1000,
            toast: true,
            showConfirmButton: false
          })
        })
      }
    },
    mounted() {
      this.getQuestionsTypes()
    }
  }
</script>

<style lang="sass" scoped>
.error
  border: 1px solid red!important
.success
  border: 1px solid green!important
.opacity
  &-enter,
  &-leave-to
    opacity: 0
  &-enter-active,
  &-leave-active
    transition: opacity .2s
.fade
  &-enter,
  &-leave-to
    opacity: 0
    transform: translate(-50%, -100%)
  &-enter-to,
  &-leave
    transform: translate(-50%, -50%)
  &-enter-active,
  &-leave-active
    transition: all .3s
</style>
