<template>
  <div class="">
    <div class="marquee" ref="marquee">
      <div v-html="text" class="marquee__inner" ref="inner"></div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'MarqueeText',
    methods: {
      animateMarquee(el, duration) {
        const innerEl = this.$refs.inner
        const innerWidth = innerEl.offsetWidth
        const cloneEl = innerEl.cloneNode(true)
        el.appendChild(cloneEl)
        let start = performance.now()
        let progress
        let translateX
        requestAnimationFrame(function step(now) {
          progress = (now - start) / duration
          if (progress > 1) {
            progress %= 1
            start = now
          }
          translateX = innerWidth * progress
          innerEl.style.transform = `translateX(-${translateX}px)`
          cloneEl.style.transform = `translateX(-${translateX}px)`
          requestAnimationFrame(step)
        })
      }
    },
    mounted() {
      window.addEventListener('load', () => {
        this.animateMarquee(this.$refs.marquee, this.duration)
      })
    },
    props: {
      duration: {
        type: Number,
        default: 10000
      },
      text: {
        type: String,
        require: true
      }
    }
  }
</script>

<style lang="sass" scoped>
  .marquee
    overflow: hidden
    cursor: default
    user-select: none
    white-space: nowrap
    &__inner
      text-align: justify
      text-align-last: justify
      white-space: nowrap
      overflow: hidden
      display: inline-block
</style>
