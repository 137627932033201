<template>
  <div>
    <div class="accordion accordion-flush" :id="name">
      <slot></slot>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'Accordion',
    props: {
      name: String
    }
  }
</script>

<style lang="sass" scoped>
</style>
