<template>
  <div class="partners-gallery">
    <Splide :options="primaryOptions" ref="main">
      <SplideSlide v-for="photo in images" :key="photo.pk">
        <img
          :src="'/media/' + photo.logo"
          :alt="photo.alt"
          class="partners-gallery__img img-fluid"
        />
      </SplideSlide>
    </Splide>
  </div>
</template>

<script>
/* eslint-disable */
import { Splide, SplideSlide } from '@splidejs/vue-splide'

export default {
  name: 'PartnerGallery',
  components: {
    Splide,
    SplideSlide
  },
  props: {
    images: { type: Array, require: false }
  },

  data() {
    return {
      primaryOptions: {
        autoplay: true,
        arrows: true,
        type: 'loop',
        pagination: false,
        perPage: this.images.length <= 10 ? this.images.length - 1 : 10,
        breakpoints: {
          1200: {
            perPage: 8
          },
          992: {
            perPage: 6
          },
          768: {
            perPage: 4
          },
          576: {
            perPage: 2
          }
        },
        perMove: 1,
        gap: '0.5rem',
        interval: 1500
      }
    }
  }
}
</script>

<style lang="sass">
.splide__slide
  justify-content: center
  display: flex
  margin-right: 15px
</style>
