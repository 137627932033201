<template>
  <div class="faculty-gallery">
    <Splide :options="primaryOptions" ref="main">
      <splide-slide v-for="photo in images" :key="photo.pk">
        <img :src="'/media/' + photo.image" alt class="faculty-gallery__img" />
      </splide-slide>
    </Splide>
    <Splide :options="thumbsOptions" ref="thumbs">
      <splide-slide v-for="photo in images" :key="photo.pk">
        <img :src="'/media/' + photo.image" alt class="faculty-gallery__img" />
      </splide-slide>
    </Splide>
  </div>
</template>

<script>
  import { Splide, SplideSlide } from '@splidejs/vue-splide'

  export default {
    name: 'FacultyGallery',
    components: {
      Splide,
      SplideSlide
    },
    props: {
      images: { type: Array, require: false }
    },

    data() {
      return {
        primaryOptions: {
          arrows: false,
          type: 'loop',
          pagination: false,
          perMove: 1,
          gap: '1rem'
        },
        thumbsOptions: {
          type: 'slide',
          rewind: true,
          gap: '.25rem',
          pagination: false,
          fixedWidth: 110,
          fixedHeight: 70,
          cover: true,
          focus: 'center',
          isNavigation: true,
          updateOnMove: true
        }
      }
    },
    mounted() {
      const thumbsSplide = this.$refs.thumbs.splide
      if (thumbsSplide) {
        this.$refs.main.sync(thumbsSplide)
      }
    }
  }
</script>

<style lang="sass">
  .faculty-gallery
    &__img
      height: 400px
      width: 100%
</style>
