<template>
  <div class="faq__questions-wrapper">
    <Accordion
      v-if="userQuestion === '' && questionsByCategory !== 0"
      name="categories"
    >
      <transition-group name="list">
        <AccordionItem
          v-for="category in questionsByCategory"
          :key="category.id"
          :title="category.name"
          name="category"
          parent="categories"
        >
          <Accordion :name="`questions-${category.id}`">
            <transition-group name="list">
              <AccordionItem
                v-for="item in category.questions"
                :key="item.id"
                :title="item.question"
                name="question"
                :parent="`questions-${category.id}`"
                showIcon
              >
                <div v-html="item.answer"></div>
              </AccordionItem>
            </transition-group>
          </Accordion>
        </AccordionItem>
      </transition-group>
    </Accordion>
    <Accordion
      v-else-if="filteredQuestions.length !== 0"
      :name="`questions-filterd`"
    >
      <transition-group name="list">
        <AccordionItem
          v-for="item in filteredQuestions"
          :key="item.id"
          :title="item.question"
          name="question"
          :parent="`questions-filterd`"
          showIcon
        >
          <div v-html="item.answer"></div>
        </AccordionItem>
      </transition-group>
    </Accordion>
    <transition v-else name="list">
      <div class="faq__accordion-category__button mx-auto mt-5">
        Ничего не найдено
      </div>
    </transition>
  </div>
</template>

<script>
  import axios from 'axios'
  import Swal from 'sweetalert2'
  import Accordion from './accordion.vue'
  import AccordionItem from './accordionItem.vue'

  export default {
    data() {
      return {
        questionsList: [],
        questionsByCategory: []
      }
    },
    components: {
      Accordion,
      AccordionItem
    },
    computed: {
      userQuestion: {
        get() {
          return this.$store.state.faq.userQuestion
        }
      },
      filteredQuestions() {
        return this.questionsList.filter((item) => {
          return item.question.toLowerCase().indexOf(this.userQuestion.toLowerCase()) > -1
        })
      }
    },
    methods: {
      getQuestions() {
        try {
          axios.get('/faq/list/').then((response) => {
            this.questionsList = response.data
          })
          axios.get('/faq/by-group/').then((response) => {
            this.questionsByCategory = response.data
          })
        } catch (error) {
          console.log(error)
          Swal.fire({
            title: 'Ошибка',
            text: 'Не удалось получить ответ сервера',
            icon: 'error',
            timer: 1000,
            showConfirmButton: false
          })
        }
      }
    },
    mounted() {
      this.getQuestions()
    }
  }
</script>

<style lang="sass" scoped>
.list
  &-enter
    transform: translateX(-100%)
    &-active
      transition: .4s all
    &-to
      transform: translateX(0%)
  &-leave
    transform: translateX(0%)
    &-active
      transition: .4s all
    &-to
      transform: translateX(100%)
</style>
