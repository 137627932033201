<template>
  <div class="world-map__countries">
    <MarqueeText :duration="25000" :text="getMonoSpacedText()" />
  </div>
</template>

<script>
  import MarqueeText from './MarqueeText.vue'
  export default {
    components: {
      MarqueeText
    },
    methods: {
      getMonoSpacedText() {
        const text = this.countries.split(' ')
        const lineLength = Math.floor(text.length / this.lines)
        const outputText = []
        for (let i = 0; i < this.lines; i++) {
          outputText.push(...text.splice(0, lineLength))
          outputText.push('<br />')
        }
        return outputText.join(' ')
      }
    },
    props: {
      countries: {
        type: String,
        require: true
      },
      lines: {
        type: Number,
        default: 10
      }
    }
  }
</script>

<style lang="sass" scoped></style>
