<template>
  <a class="mt-2" :class="shape ? 'col-12' : 'col-12 col-md-6'" :href="url">
    <img :src="img" class="theme-card" alt="Медиа мастерская" />
  </a>
</template>

<script>
  export default {
    props: {
      url: {
        type: String
      },
      shape: {
        type: Boolean
      },
      img: {
        type: String
      }
    }
  }
</script>
