<template>
  <div :class="`faq__accordion-${name}__item`">
    <h2
      :class="`faq__accordion-${name}__header`"
      :id="`${parent}-heading-${$vnode.key}`"
    >
      <div
        :class="`faq__accordion-${name}__button collapsed`"
        data-bs-toggle="collapse"
        :data-bs-target="`#${parent}-collapse-${$vnode.key}`"
        aria-expanded="false"
        :aria-controls="`${parent}-collapse-${$vnode.key}`"
      >
        <div :class="`faq__accordion-${name}__title`"  v-html="title"></div>
        <div v-if="showIcon" :class="`faq__accordion-${name}__icon`">
          <div></div>
          <div></div>
        </div>
      </div>
    </h2>
    <div
      :id="`${parent}-collapse-${$vnode.key}`"
      :class="`faq__accordion-${name}__collapse collapse`"
      :aria-labelledby="`${parent}-heading-${$vnode.key}`"
      :data-bs-parent="`#${parent}`"
    >
      <div :class="`faq__accordion-${name}__body`">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AccordionItem',
    props: {
      title: String,
      parent: String,
      name: String,
      showIcon: Boolean
    }
  }
</script>

<style lang="sass" scoped></style>
