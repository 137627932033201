<template>
  <div class="roadmap-component">
    roadmap
    {{ roadMapList }}
  </div>
</template>

<script>
  import axios from 'axios'
  // import Swal from 'sweetalert2'

  export default {
    data() {
      return {
        roadMapList: []
      }
    },

    methods: {
      getRoadMapList() {
        axios.get('/roadmap/list/').then(response => {
          this.roadMapList = response.data
        })
      }
    },
    mounted() {
      this.getRoadMapList()
    }
  }
</script>
