import $ from 'jquery'

$(function () {
  $('.owl-carousel').owlCarousel({
    items: 1,
    loop: true,
    autoHeight: true,
    nav: true,
    dots: true,
    navText: ['←', '→']
  })
  const mapPaths = document.querySelectorAll('.world-map g')
  let i = 0
  function getRandomInt(min, max) {
    return Math.floor(Math.random() * (Math.floor(max) - Math.ceil(min))) + Math.ceil(min)
  }
  mapPaths.forEach(e => {
    e.animate(
      [
        {
          transform: 'translateY(0px)'
        },
        {
          transform: `translateY(${getRandomInt(10, 20)}px)`
        }
      ],
      {
        delay: i,
        easing: 'ease-in-out',
        direction: 'alternate',
        duration: 3000,
        iterations: Infinity
      }

    )
    i += 300
  })
})
