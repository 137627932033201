import './axios'
import 'bootstrap'
import './jquery'
import './iconfont'
import './fancybox'
import './wow'
import 'owl.carousel/dist/assets/owl.carousel.css'
import '@fortawesome/fontawesome-free/js/all'
import '@fortawesome/fontawesome-free/css/all.css'

window.$ = require('jquery')
window.jQuery = require('jquery')

window.fn = require('owl.carousel')

window.Popper = require('popper.js').default
