<template>
  <div class="faq__header-search">
    <input class="faq__header-input" v-model="userQuestion" placeholder="Введите слово для поиска нужной информации" type="text" />
  </div>
</template>

<script>
  export default {
    computed: {
      userQuestion: {
        get() {
          return this.$store.state.faq.userQuestion
        },
        set(value) {
          this.$store.commit('faq/updateUserQuestion', value)
        }
      }
    }
  }
</script>
