import { Fancybox } from '@fancyapps/ui/src/Fancybox/Fancybox.js'

Fancybox.bind('[data-fancybox="gallery-graduates"]', {
  Thumbs: false,
  Toolbar: false,

  Image: {
    zoom: false,
    click: false,
    wheel: 'slide'
  }
})
Fancybox.bind('[data-fancybox="gallery-stud"]', {
  Thumbs: false,
  Toolbar: false,

  Image: {
    zoom: false,
    click: false,
    wheel: 'slide'
  }
})
